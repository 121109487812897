import { datadogLogs } from '@datadog/browser-logs';
import { useMutation } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import {
    BookingCreateModelQuery,
    BookingViewModel,
    GuestBookingCreateModelQuery,
} from 'external-apis/src/types/port';
import { ApiResponse } from 'openapi-typescript-fetch';
import { FriendlyQueryError } from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';

const useCreateBookingGuest = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher
        .path('/bookings/guest')
        .method('post')
        .create();
};

export function useConfirmBookingGuest() {
    const [lc] = useLanguageContext();
    const confirmBookingGuest = useCreateBookingGuest();

    return useMutation<
        ApiResponse<BookingViewModel> | undefined,
        Error,
        GuestBookingCreateModelQuery
    >({
        mutationFn: async (body: GuestBookingCreateModelQuery) => {
            try {
                if (body) {
                    return await confirmBookingGuest(body);
                }
            } catch (e) {
                if (e instanceof confirmBookingGuest.Error) {
                    const response = e.getActualType();
                    if (response) {
                        switch (response.status) {
                            case 401:
                                throw new FriendlyQueryError(
                                    lc.errors.port_otp_invalid,
                                    e,
                                    e.status
                                );
                            case 404:
                                throw new FriendlyQueryError(
                                    lc.errors.port_timeslots_confirm_timeout,
                                    e,
                                    e.status
                                );
                            case 429:
                                throw new FriendlyQueryError(
                                    lc.errors.port_otp_confirm_limit,
                                    e,
                                    e.status
                                );
                            default:
                                throw new FriendlyQueryError(
                                    lc.errors.default,
                                    e,
                                    e.status
                                );
                        }
                    }
                }
                throw e;
            }
        },
    });
}

const useCreateBookings = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher.path('/bookings').method('post').create();
};

export function useConfirmBooking(onSuccessBooking: () => void) {
    const [lc] = useLanguageContext();
    const confirmBooking = useCreateBookings();

    return useMutation<
        BookingViewModel | undefined,
        Error,
        BookingCreateModelQuery
    >(
        (body: BookingCreateModelQuery) =>
            confirmBookingMutation(body, lc, confirmBooking),
        {
            onSuccess: () => {
                onSuccessBooking();
            },
        }
    );
}

export class NetworkError extends Error {
    status?: number;

    constructor(message: string, status?: number) {
        super(message);
        this.status = status;
    }
}

type PartialObject = Partial<Record<string, unknown>>;
function isObject(x: unknown): x is PartialObject {
    return typeof x === 'object';
}

function isNetworkError(x: unknown) {
    return isObject(x) && hasNoHttpStatus(x);
}

function hasNoHttpStatus(e: PartialObject): e is { status: number } {
    return !e.status;
}

async function confirmBookingMutation(
    body: BookingCreateModelQuery,
    lc: LanguageContextType,
    confirmBooking: ReturnType<typeof useCreateBookings>
) {
    try {
        const result = await confirmBooking(body);
        return result.data;
    } catch (e) {
        if (e instanceof confirmBooking.Error) {
            const response = e.getActualType();
            datadogLogs.logger.error(
                'Confirm booking mutation failed: ',
                response
            );
            switch (response.status) {
                case 409:
                    if (
                        response.data.errorType === 'TimeslotReservationExpired'
                    ) {
                        throw new FriendlyQueryError(
                            lc.errors.port_timeslots_confirm_timeout,
                            e,
                            response.status
                        );
                    }
                    throw new FriendlyQueryError(
                        lc.errors.default,
                        e,
                        response.status
                    );
                default:
                    throw new FriendlyQueryError(
                        lc.errors.default,
                        e,
                        response.status
                    );
            }
        }
        if (isNetworkError(e)) {
            if (e instanceof Error) {
                datadogLogs.logger.error(
                    'Throwing retryable network error',
                    {},
                    e
                );
            }
            throw new NetworkError(lc.errors.default, e.status);
        }

        throw e;
    }
}
