import {
    AlertBanner,
    Column,
    Headline,
    IconButton,
    LoadingSpinner,
    Row,
} from '@moller/design-system';
import { Divider } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import { openPdfWithAuth } from 'src/utils/auth/authUtils';
import scrollToTopOfPage from 'src/utils/browser/scrollToTopOfPage';
import { useDamageAppraiser } from 'src/utils/hooks/useDamageAppraiser';
import {
    useMessageThread,
    useMessagesInThread,
} from 'src/utils/hooks/useMessageThreads';
import { useProfile } from 'src/utils/hooks/useProfile';
import { toServerUrl } from 'src/utils/http/utils/toServerUrl';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { openFileForMobileApp } from 'src/utils/native/pdf';
import { sortByTime } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';
import { MESSAGE_LIST } from '../index';
import {
    DamageValuationStatus,
    useDavStatusUpdate,
} from '../utils/useDavStatusUpdate';
import DeleteThread from './DeleteThread';
import Message from './Message';
import RequestInfo from './RequestInfo';
import { SendMessageInExistingThread } from './SendMessageInExistingThread';

function convertToActualMimeType(mimeType: 'PDF' | 'PNG') {
    switch (mimeType) {
        case 'PDF':
            return 'application/pdf';
        case 'PNG':
            return 'image/png';
    }
}

function getFileExtension(mimeType: 'PDF' | 'PNG') {
    switch (mimeType) {
        case 'PDF':
            return '.pdf';
        case 'PNG':
            return '.png';
    }
}

const MessageThreadContainer = styled(Column)`
    width: 100%;
    gap: var(--moller-spacing-l);
    margin-top: var(--moller-spacing-l);
`;

const StyledContainer = styled(Row)`
    margin-bottom: var(--moller-spacing-l);
`;

const MessageThreadsDamageAppraisal = () => {
    const { threadId } = useParams<{ threadId: string }>();
    const { data: profile } = useProfile();
    const threadIdFromParam = threadId ?? '';

    const navigate = useNavigate();

    const {
        data: thread,
        isLoading: isLoadingThread,
        isError: isErrorThread,
    } = useMessageThread(threadId);

    const { data: messages, isLoading: isLoadingMessages } =
        useMessagesInThread(threadId);

    const {
        data: damage,
        isLoading: isLoadingDamage,
        isError: isErrorDamage,
        refetch: refetchDamage,
    } = useDamageAppraiser(thread?.externalId);

    const { mutate: updateDavStatus } = useDavStatusUpdate(
        damage?.id?.toString()
    );

    const onMessageSent = () => {
        if (damage?.davStatus !== DamageValuationStatus.NEW) {
            updateDavStatus();
        }
    };

    useEffect(() => {
        void refetchDamage();
    }, [thread, refetchDamage]);

    const sortedMessages =
        messages &&
        [...messages].sort((a, b) => sortByTime(a.createdAt, b.createdAt));

    const filteredMessages = sortedMessages?.slice(1); // Excludes the customer's first message which has images.

    const navigateToDamage = () => {
        navigate(`/messages/?category=${MESSAGE_LIST}`);
    };

    const isLoading = isLoadingDamage || isLoadingMessages || isLoadingThread;
    const isError = isErrorThread || isErrorDamage;

    return (
        <CenteredPageContent>
            <StyledContainer>
                <IconButton
                    icon="arrow_backward"
                    label={lang.back}
                    showLabel="right"
                    onClick={navigateToDamage}
                />
            </StyledContainer>
            {isLoading && <LoadingSpinner isFullScreen />}
            {isError && (
                <AlertBanner
                    type="error"
                    message={lang.general_error_try_again}
                />
            )}
            <Column gap={'m'}>
                <Column gap={'m'}>
                    <Headline>{thread?.title}</Headline>
                    {!isLoading && !isError && damage && (
                        <RequestInfo
                            damageDesc={damage.description}
                            createdAt={damage.createdAt}
                            damageId={damage.id}
                            hasReply={filteredMessages?.some(
                                (m) => m.sender.internal
                            )}
                        />
                    )}
                    <Divider />
                    <MessageThreadContainer>
                        {filteredMessages?.map((m) => (
                            <Message
                                attachments={m.attachments?.map((a) => {
                                    const fileExtension = getFileExtension(
                                        a.mimeType
                                    );
                                    const fileName = `Bilhold-Attachment-${a.attachmentId}${fileExtension}`;
                                    return {
                                        filePath: toServerUrl(a.filePath),
                                        mimeType: convertToActualMimeType(
                                            a.mimeType
                                        ),
                                        fileName,
                                    };
                                })}
                                downloadAttachment={(attachment) => {
                                    if (isMobileApp()) {
                                        void openFileForMobileApp(
                                            attachment.filePath,
                                            attachment.mimeType,
                                            attachment.fileName
                                        );
                                    } else {
                                        void openPdfWithAuth(
                                            attachment.filePath,
                                            attachment.fileName
                                        );
                                    }
                                }}
                                messageText={m.content}
                                sentTime={m.createdAt ?? ''}
                                isSentByMe={!m.sender.internal}
                                key={m.messageId}
                                senderName={
                                    m.sender.internal
                                        ? m.sender.name?.split(' ')[0]
                                        : ''
                                }
                            />
                        ))}
                    </MessageThreadContainer>
                    <Divider />
                    <SendMessageInExistingThread
                        threadId={threadIdFromParam}
                        senderName={`${profile?.firstname} ${profile?.surname}`}
                        onMessageSent={onMessageSent}
                    />
                </Column>
                <Divider />
                <Row horizontalAlign="spaceBetween" verticalAlign="center">
                    <DeleteThread threadId={threadIdFromParam} />
                    <IconButton
                        icon="arrow_upward"
                        label={lang.toTop}
                        showLabel="left"
                        onClick={() => scrollToTopOfPage()}
                    />
                </Row>
            </Column>
        </CenteredPageContent>
    );
};

export default MessageThreadsDamageAppraisal;
