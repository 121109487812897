import { Description } from '@moller/design-system';
import { useState } from 'react';
import { styled } from 'styled-components';
import { SentimentIcons } from './SentimentIcons';
import { SentimentInput } from './SentimentInput';

const StyledDescription = styled(Description)`
    margin-top: var(--moller-spacing-l);
    margin-bottom: var(--moller-spacing-m);
    text-align: center;
    font-size: 1rem;
`;

export type Sentiment =
    | 'sentiment_dissatisfied'
    | 'sentiment_neutral'
    | 'sentiment_satisfied'
    | '';

interface Props {
    customTitle?: string;
    specifiedService?: string;
}

const FeedbackPrompt = ({ customTitle, specifiedService }: Props) => {
    const [showRatingIcons, setShowRatingIcons] = useState(true);
    const [sentiment, setSentiment] = useState<Sentiment>('');

    const feedbackTitle = customTitle || 'Hva synes du om denne siden?';

    if (!showRatingIcons && !sentiment) {
        return null;
    }

    if (!showRatingIcons && sentiment) {
        return (
            <SentimentInput
                sentiment={sentiment}
                setSentiment={setSentiment}
                specifiedService={specifiedService}
            />
        );
    }

    return (
        <>
            <StyledDescription>{feedbackTitle}</StyledDescription>
            <SentimentIcons
                setShowRatingIcons={setShowRatingIcons}
                setSentiment={setSentiment}
            />
        </>
    );
};

export default FeedbackPrompt;
