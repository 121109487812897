import { Capacitor } from '@capacitor/core';
import { datadogRum } from '@datadog/browser-rum';
import {
    AlertBanner,
    Calendar,
    Description,
    breakpointTablet,
    nextMonth,
} from '@moller/design-system';
import { TextSkeleton } from '@moller/design-system/building-blocks/skeletons';
import {
    differenceInBusinessDays,
    differenceInCalendarDays,
    formatISO,
    isBefore,
    isSameDay,
    isSameMonth,
    startOfMonth,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
    DealerViewModel,
    ServiceViewModel,
    TimeSlotViewModel,
} from 'external-apis/src/types/port';
import { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { useGetApiLocaleConfig } from '../../_api/apiLocale';
import { useGetDealers } from '../../_api/http/dealers';
import { useGetServices } from '../../_api/http/services';
import {
    useGetTimeSlots,
    usePrefetchTimeslots,
} from '../../_api/http/timeslots';
import { useGetVehicle } from '../../_api/http/vehicle';
import { FlexColumn } from '../../components/FlexColumn';
import { QueryError } from '../../components/QueryError';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { formatDealerName } from '../../lib/util/datadogFormatDealerName';
import { Done } from '../sectionUtils';
import {
    ChooseTimeSlotDependencies,
    ChooseTimeSlotState,
} from './ChooseTimeslotSection';
import { DealersAndSlots } from './DealersAndSlots';

export function ChooseTimeSlotEdit({
    ...props
}: {
    dependencies: Done<ChooseTimeSlotDependencies>;
    setSectionState: (x: ChooseTimeSlotState) => void;
}) {
    // We need to prevent users to navigate before loading the first
    // available date which we will use as minDate
    const [maxDate, setMaxDate] = useState<Date | undefined>(new Date());
    const [minDate, setMinDate] = useState<Date>(new Date());
    // The date that we use as startTime when retrieving timeSlots.
    const [startTime, setStartTime] = useState(new Date());
    // This state should live in choose time slot section so that
    // the user can keep their choice of date when changing sections,
    // However this require that frontend control what month to show.
    const [selectedDate, setSelectedDate] = useState<Date>();

    const [firstAvailableDate, setFirstAvailableDate] = useState<Date>();

    const [reservedTimeSlotError, setReservedTimeSlotError] =
        useState<Error | null>(null);

    const vehicleResponse = useGetVehicle(
        props.dependencies.chooseVehicle.data.registrationNumber
    );

    const dealersResponse = useGetDealers();

    const dealerNumbers = [props.dependencies.chooseDealer.data.dealerNumber];

    const serviceIds =
        props.dependencies.standardServices.data.selectedServices.map(
            (x) => x.serviceId
        );

    const servicesResponse = useGetServices({
        vin: vehicleResponse.data?.vehicleIdentificationNumber,
        dealerNumbers,
        mileage: props.dependencies.standardServices.data.mileage,
    });

    const { countryCode } = useGetApiLocaleConfig();
    const { timeZone } = useGetApiLocaleConfig();

    const body = {
        vin: vehicleResponse.data?.vehicleIdentificationNumber ?? '',
        dealerFilter: { selectedDealerNumbers: dealerNumbers },
        selectedServiceIds: serviceIds,
        endTime: formatISO(
            new Date(startTime.getFullYear(), startTime.getMonth() + 1, 0)
        ),
        startTime: formatISO(getEffectiveStartTime(startTime)),
        countryCode,
    };

    const timeSlotsResponse = useGetTimeSlots(body);
    usePrefetchTimeslots(body);

    // useHandleTimeSlotsResponse
    useEffect(() => {
        const handleIsDifferentMonthAndDateUnavailable = (
            data: TimeSlotViewModel[]
        ) => {
            if (
                selectedDate &&
                data.length > 0 &&
                !isSameMonth(selectedDate, new Date(data[0].startTime)) &&
                isSelectedDateUnavailable(selectedDate, minDate, data)
            ) {
                setSelectedDate(undefined);
            }
        };

        const handleIsEmptyAndNoPreviouslyReturnedSlots = (
            data: TimeSlotViewModel[]
        ) => {
            if (
                data.length === 0 &&
                maxDate &&
                startTime < nextMonth(new Date(), 3)
            ) {
                setStartTime(nextMonth(startTime, 1));
            } else if (maxDate) {
                setMinDate(startTime);
                setMaxDate(undefined);
            }
        };

        const handleIsFirstReturnedSlot = (data: TimeSlotViewModel[]) => {
            if (maxDate && data.length > 0) {
                setMinDate(getFirstAvailableDate(data));
                setMaxDate(undefined);
                setFirstAvailableDate(getFirstAvailableDate(data));
            }
        };

        if (timeSlotsResponse.data) {
            handleIsDifferentMonthAndDateUnavailable(timeSlotsResponse.data);
            handleIsEmptyAndNoPreviouslyReturnedSlots(timeSlotsResponse.data);
            handleIsFirstReturnedSlot(timeSlotsResponse.data);
        }
    }, [maxDate, minDate, selectedDate, startTime, timeSlotsResponse.data]);

    useReportTimeslots({
        timeSlots: timeSlotsResponse.data,
        services: servicesResponse.data?.Standard,
        dealers: dealersResponse.data,
        selectedDealerNumber: props.dependencies.chooseDealer.data.dealerNumber,
        selectedServiceIds:
            props.dependencies.standardServices.data.selectedServices.map(
                (x) => x.serviceId
            ),
    });

    const availableDates = new Set(
        timeSlotsResponse.data?.map((x) =>
            formatInTimeZone(x.startTime, timeZone, 'yyyy-MM-dd')
        )
    );

    const isError =
        timeSlotsResponse.isError ||
        dealersResponse.isError ||
        servicesResponse.isError ||
        vehicleResponse.isError;

    // maxDate in isLoading is meant to place the calendar in a loadingstate when
    // we havn't yet figured out what month to show. This can happen if the user changes dealer or service
    // to a combination which has no available timeslots in the current month
    const isLoading =
        timeSlotsResponse.isLoading ||
        dealersResponse.isLoading ||
        servicesResponse.isLoading ||
        vehicleResponse.isLoading ||
        !!maxDate;

    const selectedDealer = dealersResponse.data?.find(
        (x) => x.id === props.dependencies.chooseDealer.data.dealerNumber
    );
    return (
        <>
            <QueryError
                error={reservedTimeSlotError}
                isError={!!reservedTimeSlotError}
            />
            <FirstAvailableDateHelperText
                firstAvailableDate={firstAvailableDate}
                isLoading={isLoading}
            />
            <ViewContainer>
                <Calendar
                    selected={selectedDate}
                    setSelected={setSelectedDate}
                    isDateUnavailable={(x: Date) =>
                        x === selectedDate
                            ? true
                            : !availableDates.has(
                                  formatInTimeZone(x, timeZone, 'yyyy-MM-dd')
                              )
                    }
                    onNavigate={(x: Date) => {
                        if (!isSameMonth(x, startTime)) {
                            setStartTime(startOfMonth(x));
                            setSelectedDate(undefined);
                        }
                    }}
                    isLoading={isLoading}
                    minDate={minDate}
                    maxDate={maxDate}
                />
                <QueryError
                    error={timeSlotsResponse.error}
                    isError={timeSlotsResponse.isError}
                />
                {!(isError || isLoading) && selectedDate && (
                    <DealersAndSlots
                        vin={vehicleResponse.data.vehicleIdentificationNumber}
                        timeSlots={timeSlotsResponse.data}
                        dealers={dealersResponse.data}
                        selectedDealerId={
                            props.dependencies.chooseDealer.data.dealerNumber
                        }
                        services={[
                            ...servicesResponse.data.Standard,
                            ...servicesResponse.data.Recommended,
                        ]}
                        selectedDate={selectedDate}
                        setSectionState={props.setSectionState}
                        selectedDealerNumbers={dealerNumbers}
                        selectedServiceIds={serviceIds}
                        setReservedTimeSlotError={setReservedTimeSlotError}
                    />
                )}
                {!(isLoading || isError) &&
                    !firstAvailableDate &&
                    noTimeSlotsAvailable(timeSlotsResponse.data) && (
                        <NoTimeslotBanner dealer={selectedDealer} />
                    )}
            </ViewContainer>
        </>
    );
}

function NoTimeslotBanner({ dealer }: { dealer: DealerViewModel | undefined }) {
    const isWeb = Capacitor.getPlatform() === 'web';
    const [lc] = useLanguageContext();

    if (!dealer) {
        return (
            <AlertBanner
                type="warning"
                message={lc.chooseTimeslot.no_available_dates}
                density="compact"
            />
        );
    }
    if (!isWeb) {
        return (
            <AlertBanner
                type="warning"
                message={lc.chooseTimeslot.no_available_dates}
                action={{
                    label: lc.chooseTimeslot.call_dealer,
                    onClick: () => handleCall(dealer.phoneNumber),
                }}
                density="compact"
            />
        );
    }
    return (
        <AlertBanner
            type="warning"
            message={
                lc.chooseTimeslot.no_available_dates +
                ' ' +
                lc.chooseTimeslot.call_dealer +
                ': ' +
                dealer.phoneNumber
            }
            density="compact"
        />
    );
}

function handleCall(phone: string) {
    const phoneHref = `tel:${phone.replace(/\s/g, '')}`;
    window.location.href = phoneHref;
}

interface FirstAvailableDateHelperProps {
    firstAvailableDate: Date | undefined;
    isLoading: boolean;
}

function FirstAvailableDateHelperText({
    firstAvailableDate,
    isLoading,
}: FirstAvailableDateHelperProps) {
    const [lc, locale] = useLanguageContext();

    if (isLoading) {
        return <TextSkeleton width="260px" />;
    }

    if (!firstAvailableDate) {
        return <p>{lc.chooseTimeslot.no_available_dates_helper}</p>;
    }

    return (
        <Description>
            {lc.chooseTimeslot.first_available_timeslot.replace(
                '{month}',
                firstAvailableDate.toLocaleDateString(locale.stringLocale, {
                    month: 'long',
                })
            )}
        </Description>
    );
}

function noTimeSlotsAvailable(timeSlots: TimeSlotViewModel[] | undefined) {
    return (timeSlots?.length ?? 0) < 1;
}

// when editing changing selected dealer or selected services there is a chance that
// the selected date has become unavailable.
function isSelectedDateUnavailable(
    selectedDate: Date,
    minDate: Date | undefined,
    data: TimeSlotViewModel[]
) {
    if (
        minDate &&
        isBefore(selectedDate, minDate) &&
        !isSameDay(selectedDate, minDate)
    ) {
        return true;
    }
    if (
        selectedDate &&
        !data.some((x) => isSameDay(new Date(x.startTime), selectedDate))
    ) {
        return true;
    }
}

function getEffectiveStartTime(possibleStartDate: Date) {
    const today = new Date();
    if (possibleStartDate < today) {
        return today;
    }
    return possibleStartDate;
}

function getFirstAvailableDate(timeslots: TimeSlotViewModel[]) {
    return new Date(timeslots[0].startTime);
}

function firstAvailableDateForDealer(
    timeslots: TimeSlotViewModel[],
    dealerNumber: string
) {
    const firstTimeslot = timeslots.find(
        (x) => x.dealerNumber === dealerNumber
    );

    if (!firstTimeslot) {
        return undefined;
    }

    return new Date(firstTimeslot.startTime);
}

const ViewContainer = styled(FlexColumn)`
    align-items: start;
    gap: var(--moller-spacing-l);

    @media (min-width: ${breakpointTablet}) {
        flex-direction: row;
    }
`;

function useReportTimeslots({
    timeSlots,
    services,
    dealers,
    selectedDealerNumber,
    selectedServiceIds,
}: {
    timeSlots?: TimeSlotViewModel[];
    services?: ServiceViewModel[];
    dealers?: DealerViewModel[];
    selectedDealerNumber: string;
    selectedServiceIds: string[];
}) {
    const reported = useRef(false);

    useEffect(() => {
        if (reported.current) {
            return;
        }
        if (!services || !timeSlots || !dealers) {
            return;
        }
        const selectedServices = services.filter((x) =>
            selectedServiceIds.includes(x.id)
        );

        const dealer = dealers.find(
            (dealer) => dealer.id === selectedDealerNumber
        );

        if (!dealer) {
            return;
        }

        const firstAvailable = firstAvailableDateForDealer(
            timeSlots,
            selectedDealerNumber
        );
        if (firstAvailable) {
            datadogRum.addAction('Search time slots', {
                dealerName: formatDealerName(dealer.name),
                dealerNumber: selectedDealerNumber,
                services: selectedServices.map((x) => x.name).toString(),
                daysUntilFirstAvailableTimeSlotCalendarDays:
                    differenceInCalendarDays(firstAvailable, new Date()),
                daysUntilFirstAvailableTimeSlotBusinessDays:
                    differenceInBusinessDays(firstAvailable, new Date()),
            });
            reported.current = true;
        }
    }, [
        selectedDealerNumber,
        dealers,
        services,
        selectedServiceIds,
        timeSlots,
    ]);
}
