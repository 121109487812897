import { Column, useLocalFormState } from '@moller/design-system';
import {
    required,
    validation,
} from '@moller/design-system/utilities/validation';
import { CarViewModel } from 'external-apis/src/types/carcare';
import { DealerViewModel } from 'external-apis/src/types/port';
import { useFlag } from 'feature-toggle';
import { ReactNode, useEffect, useRef, useState } from 'react';
import {
    useGetDealerAffiliation,
    useGetDealers,
} from '../../_api/http/dealers';
import {
    isActiveTireHotelDeal,
    useGetTireHotelDeal,
} from '../../_api/http/tireHotelDeal';
import { useGetVehicle } from '../../_api/http/vehicle';
import { useBookingSearchParameters } from '../../_api/urlSearchParameters';
import { DDRumPrivacySetting } from '../../components/DDRumPrivacySetting';
import { FlexRow } from '../../components/FlexRow';
import { QueryError } from '../../components/QueryError';
import { BilholdNextButton } from '../../components/bilhold/BilholdNextButton';
import {
    BilholdViewHidden,
    BilholdViewLayout,
} from '../../components/bilhold/BilholdView';
import { BilholdViewTitle } from '../../components/bilhold/BilholdViewTitle';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { useFastTrack } from '../../lib/useFastTrack';
import { useScrollRefIntoView } from '../../lib/util/scroll';
import { ChooseVehicleResult } from '../choose-vehicle/ChooseVehicleSection';
import { SectionState, checkDoneDependencies } from '../sectionUtils';
import { ChooseDealerDone } from './ChooseDealerDone';
import { ChooseDealerEdit } from './ChooseDealerEdit';
import { ChooseAffiliatedDealerLoader } from './ChooseDealerLoader';

export type DealerForm = {
    selectedDealer: string | undefined;
};

export interface ChooseDealerData {
    dealerNumber: string;
    adapterId: string;
}

export type DealerSectionState = SectionState<ChooseDealerData>;
type DealerSectionDependencies = {
    chooseVehicle: ChooseVehicleResult;
};

export function useChooseDealer({
    initialState,
    dependencies,
}: {
    initialState: DealerSectionState;
    dependencies: DealerSectionDependencies;
}) {
    const [state, setState] = useState(initialState);
    const depsAreDone = checkDoneDependencies(dependencies);

    useEffect(() => {
        setState((s) =>
            depsAreDone
                ? s.viewMode === 'hidden'
                    ? { viewMode: 'edit' }
                    : s
                : { viewMode: 'hidden' }
        );
    }, [depsAreDone]);

    if (depsAreDone && state.viewMode === 'done') {
        const done = {
            result: {
                viewMode: state.viewMode,
                data: state.data,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
                data: state.data,
            },
        };
        return done;
    }

    if (depsAreDone && state.viewMode === 'edit') {
        const edit = {
            result: {
                viewMode: state.viewMode,
            },
            props: {
                viewMode: state.viewMode,
                dependencies,
                setSectionState: setState,
            },
        };
        return edit;
    }

    return {
        result: {
            viewMode: 'hidden' as const,
        },
        props: {
            viewMode: 'hidden' as const,
            setSectionState: setState,
        },
    };
}

type ChooseDealerProps = ReturnType<typeof useChooseDealer>['props'] & {
    setIsBookingRequest: (isRequest: boolean) => void;
};

export type ChooseDealerResult = ReturnType<typeof useChooseDealer>['result'];

function setIsBookingRequestForAnnata(
    selectedDealerNumber: string,
    availableDealers: DealerViewModel[],
    bookingRequestToggleEnabled: boolean,
    setIsRequest: (isRequest: boolean) => void
) {
    const selectedDealer = availableDealers.find(
        (dealer) => dealer.id === selectedDealerNumber
    );
    if (
        selectedDealer &&
        selectedDealer.adapterId === 'annata-adapter' &&
        bookingRequestToggleEnabled
    ) {
        setIsRequest(true);
    } else {
        setIsRequest(false);
    }
}

function isDealerCertified(
    vehicle: CarViewModel,
    dealerNumber: string,
    dealers: DealerViewModel[]
) {
    const dealer = dealers.find((dealer) => dealer.id === dealerNumber);
    return dealer?.serviceCertifications.some(
        (certification) =>
            certification.toLowerCase() === vehicle.brandCode.toLowerCase()
    );
}

const ChooseDealerSection: React.FC<
    ChooseDealerProps & {
        dealers: DealerViewModel[];
        affiliatedDealer: string | undefined;
        vehicle: CarViewModel;
        setIsBookingRequest: (isRequest: boolean) => void;
    }
> = (props) => {
    const { viewMode, dealers, affiliatedDealer, vehicle, setSectionState } =
        props;
    const isInitialRender = useRef(true);

    const dealerNumber =
        useBookingSearchParameters().dealerNumber || affiliatedDealer;

    const useBookingRequestEnabled = useFlag('use-booking-request-annata');

    const validInitialDealerNumber =
        dealerNumber &&
        dealers?.some((dealer) => dealer.id === dealerNumber) &&
        isDealerCertified(vehicle, dealerNumber, dealers)
            ? dealerNumber
            : undefined;

    const [lc] = useLanguageContext();

    const dealerForm = useLocalFormState<DealerForm>(
        {
            selectedDealer: validInitialDealerNumber,
        },
        {
            selectedDealer: required(
                validation(),
                lc.errors.singlechoice_dealer_validation
            ),
        }
    );

    useEffect(() => {
        if (
            dealerForm.state.raw.selectedDealer &&
            isInitialRender.current &&
            viewMode === 'edit'
        ) {
            setSectionState({
                viewMode: 'done',
                data: {
                    dealerNumber: dealerForm.state.raw.selectedDealer,
                    adapterId:
                        dealers.find(
                            (x) => x.id === dealerForm.state.raw.selectedDealer
                        )?.adapterId ?? 'unknown',
                },
            });
            setIsBookingRequestForAnnata(
                dealerForm.state.raw.selectedDealer,
                dealers,
                useBookingRequestEnabled,
                props.setIsBookingRequest
            );
        }
        isInitialRender.current = false;
    }, [
        dealerForm.state.raw.selectedDealer,
        setSectionState,
        viewMode,
        dealers,
        props.setIsBookingRequest,
        useBookingRequestEnabled,
    ]);

    return (
        <>
            {viewMode === 'edit' && (
                <Column gap={'xxs'}>
                    <ChooseDealerEdit
                        dealers={dealers}
                        dealerForm={dealerForm}
                        vehicle={vehicle}
                        preferredDealerId={affiliatedDealer}
                    />
                    <BilholdNextButton
                        onClick={() => {
                            // QUICK FIX for crash on next button click
                            if (
                                dealerForm.state.isValid &&
                                dealerForm.state.validated.selectedDealer
                            ) {
                                setSectionState({
                                    viewMode: 'done',
                                    data: {
                                        dealerNumber:
                                            dealerForm.state.validated
                                                .selectedDealer,
                                        adapterId:
                                            dealers.find(
                                                (x) =>
                                                    x.id ===
                                                    dealerForm.state.validated
                                                        .selectedDealer
                                            )?.adapterId ?? 'unknown',
                                    },
                                });
                                setIsBookingRequestForAnnata(
                                    dealerForm.state.validated.selectedDealer,
                                    dealers,
                                    useBookingRequestEnabled,
                                    props.setIsBookingRequest
                                );
                            } else {
                                dealerForm.showAllErrors();
                            }
                        }}
                    />
                </Column>
            )}
            {viewMode === 'done' && dealers && (
                <ChooseDealerDone
                    form={dealerForm}
                    selectedDealerNumber={props.data.dealerNumber}
                    dealers={dealers}
                />
            )}
        </>
    );
};

const DealerViewWrapper: React.FC<
    Pick<ChooseDealerProps, 'viewMode' | 'setSectionState'> & {
        isActiveTireHotelDeal?: boolean;
        children?: ReactNode;
    }
> = (props) => {
    const [lc] = useLanguageContext();

    const sectionRef = useRef(null);
    useScrollRefIntoView(props.viewMode === 'edit', sectionRef);

    return (
        <DDRumPrivacySetting privacyLevel="allow">
            <BilholdViewLayout>
                <FlexRow ref={sectionRef}>
                    <BilholdViewTitle
                        title={
                            props.isActiveTireHotelDeal
                                ? lc.chooseDealer.tire_hotel
                                : lc.chooseDealer.title
                        }
                        isEditable={
                            props.isActiveTireHotelDeal
                                ? false
                                : props.viewMode === 'done'
                        }
                        onClick={() =>
                            props.setSectionState({ viewMode: 'edit' })
                        }
                    />
                </FlexRow>
                {props.children}
            </BilholdViewLayout>
        </DDRumPrivacySetting>
    );
};

function ChooseDealerHidden() {
    const [lc] = useLanguageContext();
    return <BilholdViewHidden desc={lc.chooseDealer.description} />;
}

export const ChooseDealer: React.FC<ChooseDealerProps> = (props) => {
    const { dealerGroup } = useBookingSearchParameters();
    const response = useGetDealers(dealerGroup);

    const dependencies =
        props.viewMode !== 'hidden' ? props.dependencies : undefined;

    const vehicleResponse = useGetVehicle(
        dependencies?.chooseVehicle.data.registrationNumber
    );

    const vin = vehicleResponse?.data?.vehicleIdentificationNumber ?? '';
    const affiliatedDealerResponse = useGetDealerAffiliation(vin);
    const fastTrack = useFastTrack();
    const tireHotelDealResponse = useGetTireHotelDeal(vin, {
        enabled: fastTrack.fastTrack === 'tire-change',
    });

    if (props.viewMode === 'hidden') {
        return (
            <DealerViewWrapper {...props}>
                <ChooseDealerHidden />
            </DealerViewWrapper>
        );
    }

    if (vehicleResponse.isLoading) {
        return (
            <DealerViewWrapper {...props}>
                <ChooseAffiliatedDealerLoader />
            </DealerViewWrapper>
        );
    }

    const isLoadingPreferredDealer =
        affiliatedDealerResponse.isLoading ||
        (fastTrack.fastTrack === 'tire-change' &&
            tireHotelDealResponse.isLoading);

    if (isLoadingPreferredDealer) {
        return (
            <DealerViewWrapper {...props}>
                <ChooseAffiliatedDealerLoader />
            </DealerViewWrapper>
        );
    }

    // This loader is in practice only used when refetching due to condition response.isSuccess
    if (response.isLoading && props.viewMode === 'edit') {
        return <ChooseAffiliatedDealerLoader />;
    }

    if (vehicleResponse.isError) {
        return (
            <DealerViewWrapper {...props}>
                <QueryError error={vehicleResponse.error} isError />
            </DealerViewWrapper>
        );
    }

    // Tire hotel deal should take precedence (it is only used in tire-change fast track) over preferred dealer
    const affiliatedDealerId =
        tireHotelDealResponse.isSuccess &&
        isActiveTireHotelDeal(tireHotelDealResponse.data)
            ? tireHotelDealResponse.data.dealerNumber
            : affiliatedDealerResponse.isSuccess
            ? affiliatedDealerResponse.data?.preferredDealerId ?? undefined
            : undefined;

    if (response.isSuccess && !isLoadingPreferredDealer) {
        return (
            <DealerViewWrapper
                {...props}
                isActiveTireHotelDeal={
                    tireHotelDealResponse.isSuccess &&
                    isActiveTireHotelDeal(tireHotelDealResponse.data)
                }
            >
                {props.viewMode === 'edit' && (
                    <QueryError
                        error={affiliatedDealerResponse.error}
                        isError={affiliatedDealerResponse.isError}
                    />
                )}
                <ChooseDealerSection
                    {...props}
                    dealers={response.data}
                    affiliatedDealer={affiliatedDealerId}
                    vehicle={vehicleResponse.data}
                />
            </DealerViewWrapper>
        );
    }

    return (
        <DealerViewWrapper {...props}>
            <QueryError isError={response.isError} error={response.error} />
        </DealerViewWrapper>
    );
};
