import { ConfigProvider } from 'booking/lib/config/configContext';
import { LanguageProvider } from 'booking/lib/languages/languageContext';
import {
    Route,
    RouterProvider,
    Routes,
    createBrowserRouter,
} from 'react-router-dom';
import AddCar from 'src/features/add-car';
import { AboutPage } from 'src/features/competition/pages/AboutPage';
import { CompetitionPage } from 'src/features/competition/pages/CompetitionPage';
import { PrivacyStatementPage } from 'src/features/competition/pages/PrivacyStatementPage';
import { TermsPage } from 'src/features/competition/pages/TermsPage';
import { MessageThreadsChat } from 'src/features/contact/message-threads/MessageThreadsChat';
import { MessageThreadsChatNewMessage } from 'src/features/contact/message-threads/MessageThreadsChatNewMessage';
import MessageThreadsDamageAppraisal from 'src/features/contact/message-threads/MessageThreadsDamageAppraisal';
import DamageAppraisalView from 'src/features/damage/Damage';
import DamageRedirect from 'src/features/damage/components/DamageRedirect';
import { PayloadCalculator } from 'src/features/electric-car-playground/payload/payload-calculator/PayloadCalculator';
import Explore from 'src/features/explore';
import { ExploreSkodaPage } from 'src/features/explore/pages/ExploreSkodaPage';
import CustomProduct from 'src/features/home/current-actions/waiting-program/CustomProduct';
import { ProductPage } from 'src/features/home/current-actions/waiting-program/ProductPage';
import { PurchaseConfirmationPage } from 'src/features/home/current-actions/waiting-program/PurchaseConfirmationPage';
import { Emissions } from 'src/features/my-car/emissions-calculator/Emissions';
import TermsOfService from 'src/features/user-profile/article-pages/TermsOfService';
import { Layout } from '../Layout';
import { DetailedAppointment } from '../features/appointment/detailed-appointment/DetailedAppointmentView';
import Begin from '../features/begin';
import AppRedirect from '../features/begin/app-redirect';
import { Booking } from '../features/booking';
import Messages from '../features/contact';
import CreateNewUser from '../features/create-new-user/CreateNewUser';
import { Home } from '../features/home';
import { MyCar } from '../features/my-car';
import { DamageAppraiser } from '../features/my-car/damage-appraiser';
import SvaServiceDeals from '../features/my-car/sva-service-deals';
import { Videos } from '../features/my-car/videos';
import Warranty from '../features/my-car/warranty';
import { Appointment } from '../features/my-car/workshop-history';
import MyOrderedCar from '../features/my-ordered-car';
import Cookies from '../features/user-profile/article-pages/Cookies';
import Privacy from '../features/user-profile/article-pages/Privacy';
import DeleteAccount from '../features/user-profile/delete-user/DeleteAccount';
import MyPreferredDealer from '../features/user-profile/my-preferred-dealer';
import ProfileView from '../features/user-profile/profile-view';
import UserSettings from '../features/user-profile/user-settings';
import AppReviewLogin from '../utils/AppReviewLogin';
import { ApplicationErrorBoundary } from '../utils/ApplicationErrorBoundary';
import LoginCallback from '../utils/auth/loginCallback';
import DevTools from '../utils/dev-tools/Index';
import AuthRoute from './AuthRoute';
import NoAuthRoute from './NoAuthRoute';
import NoContent from './NoContent';
import Maintenance from './Maintenance';
import {
    APP_REVIEW_LOGIN,
    DEV_TOOLS,
    PATH_ADD_CAR,
    PATH_APP,
    PATH_BEGIN,
    PATH_BOOKING_ROOT,
    PATH_BOOKING_WITH_PATH_PARAMS,
    PATH_COMPETITION,
    PATH_COMPETITION_ABOUT,
    PATH_COMPETITION_PRIVACY_STATEMENT,
    PATH_COMPETITION_TERMS,
    PATH_COOKIES,
    PATH_CREATE_NEW_USER,
    PATH_DAMAGE,
    PATH_DELETE_ACCOUNT,
    PATH_DETAILED_APPOINTMENT,
    PATH_EXPLORE,
    PATH_EXPLORE_SKODA,
    PATH_HOME,
    PATH_LOGIN_CALLBACK_OIDC,
    PATH_MESSAGES,
    PATH_MESSAGE_THREAD,
    PATH_MESSAGE_THREAD_CHAT,
    PATH_MESSAGE_THREAD_CHAT_NEW_MESSAGE,
    PATH_MY_CAR,
    PATH_MY_ORDERED_CAR_WITH_ID,
    PATH_MY_PREFERRED_DEALER,
    PATH_MY_PROFILE,
    PATH_PRIVACY,
    PATH_PRODUCT,
    PATH_PROFILE_OVERVIEW,
    PATH_SERVICE_DEAL,
    PATH_TERMS_OF_SERVICE,
    PATH_WARRANTY,
} from './routes';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';

const router = createBrowserRouter([
    {
        path: '*',
        element: <Layout />,
        children: [{ path: '*', element: <RootWrapper /> }],
        ErrorBoundary: ApplicationErrorBoundary,
    },
]);

export const Router = () => <RouterProvider router={router} />;

function RootWrapper() {
    const ShowMaintenanceBanner = useFeatureToggle('ShowMaintenanceBanner');
    return ShowMaintenanceBanner ? <Maintenance /> : <Root />;
}
/**
 * Legacy react-router route definition. DO NOT EXTEND!
 * New routes should be added to the router definition above.
 */

function Root() {
    return (
        <Routes>
            {/* Normal routes */}
            <Route
                path={PATH_LOGIN_CALLBACK_OIDC}
                element={<LoginCallback />}
            />
            <Route path={`${PATH_WARRANTY}/*`} element={<Warranty />} />
            <Route
                path={`${PATH_SERVICE_DEAL}/*`}
                element={<SvaServiceDeals />}
            />
            <Route path={PATH_PRIVACY} element={<Privacy />} />
            <Route path={PATH_COOKIES} element={<Cookies />} />
            <Route path={PATH_TERMS_OF_SERVICE} element={<TermsOfService />} />
            <Route path={PATH_APP} element={<AppRedirect />} />

            {/* Routes for TDF Competition */}
            <Route path={PATH_COMPETITION_ABOUT} element={<AboutPage />} />
            <Route path={PATH_COMPETITION} element={<CompetitionPage />} />
            <Route path={PATH_COMPETITION_TERMS} element={<TermsPage />} />
            <Route
                path={PATH_COMPETITION_PRIVACY_STATEMENT}
                element={<PrivacyStatementPage />}
            />

            {/* Routes you should not see if you are logged in */}
            <Route
                path={PATH_BEGIN}
                element={<NoAuthRoute element={<Begin />} />}
            />
            <Route
                path={PATH_CREATE_NEW_USER}
                element={<NoAuthRoute element={<CreateNewUser />} />}
            />
            <Route path={DEV_TOOLS} element={<DevTools />} />
            <Route path={APP_REVIEW_LOGIN} element={<AppReviewLogin />} />
            {/* Routes you should only see if you are logged in */}
            <Route
                path={PATH_HOME}
                element={<AuthRoute element={<Home />} />}
            />
            <Route
                path={PATH_MY_PROFILE}
                element={<AuthRoute element={<UserSettings />} />}
            />
            <Route
                path={PATH_MY_PREFERRED_DEALER}
                element={<MyPreferredDealer />}
            />
            <Route
                path={PATH_MESSAGES}
                element={<AuthRoute element={<Messages />} />}
            />
            <Route
                path={PATH_MESSAGE_THREAD}
                element={
                    <AuthRoute element={<MessageThreadsDamageAppraisal />} />
                }
            />
            <Route
                path={PATH_EXPLORE}
                element={<AuthRoute element={<Explore />} />}
            />
            <Route
                path={PATH_EXPLORE_SKODA}
                element={<AuthRoute element={<ExploreSkodaPage />} />}
            />
            <Route
                path={PATH_MESSAGE_THREAD_CHAT_NEW_MESSAGE}
                element={
                    <ConfigProvider
                        // TODO: Denne er KUN nødvendig for å få satt countryCode i useGetDealers.
                        //       Burde heller sende den som parameter?
                        config={{
                            apiLocaleId: 'no',
                            bookingParameters: {
                                serviceIds: [],
                                categories: [],
                            },
                            defaultApiLocale: 'no',
                            searchableDealerNumbers: [],
                        }}
                    >
                        <LanguageProvider languageId="no">
                            <AuthRoute
                                element={<MessageThreadsChatNewMessage />}
                            />
                        </LanguageProvider>
                    </ConfigProvider>
                }
            />
            <Route
                path={PATH_MESSAGE_THREAD_CHAT}
                element={<AuthRoute element={<MessageThreadsChat />} />}
            />
            <Route
                path={`${PATH_MY_CAR}/*`}
                element={<AuthRoute element={<MyCar />} />}
            ></Route>

            <Route
                path={`${PATH_PRODUCT}/:carId/customer-request`}
                element={<AuthRoute element={<CustomProduct />} />}
            />
            <Route
                path={`${PATH_PRODUCT}/:carId/:articleNumber`}
                element={<AuthRoute element={<ProductPage />} />}
            />
            <Route
                path={`${PATH_PRODUCT}/:carId/:articleNumber/confirmation`}
                element={<AuthRoute element={<PurchaseConfirmationPage />} />}
            />
            <Route
                path={`${PATH_MY_CAR}/videos`}
                element={<AuthRoute element={<Videos />} />}
            />
            <Route
                path={`${PATH_MY_CAR}/damageAppraiser`}
                element={<AuthRoute element={<DamageAppraiser />} />}
            />
            <Route
                path={`${PATH_MY_CAR}/appointments`}
                element={<AuthRoute element={<Appointment />} />}
            />
            <Route
                path={`${PATH_MY_CAR}/emissions`}
                element={<AuthRoute element={<Emissions />} />}
            />
            <Route
                path={`${PATH_DETAILED_APPOINTMENT}/*`}
                element={<AuthRoute element={<DetailedAppointment />} />}
            />
            <Route
                path={`${PATH_ADD_CAR}/:licensePlate?`}
                element={<AuthRoute element={<AddCar />} />}
            />
            <Route
                /*
                Handles routes like
                  - /booking/vin/<some-vin>
                  - /booking/booking/<booking-id>
                  - /booking/vin/<some-vin>/booking/<booking-id>
 
                Remove this <Route> element when old booking is removed AND all
                external URLs to booking has been changed
                */
                path={PATH_BOOKING_WITH_PATH_PARAMS}
                element={<AuthRoute element={<Booking />} />}
            />
            <Route
                path={`${PATH_BOOKING_ROOT}/*`}
                element={<AuthRoute element={<Booking />} />}
            />
            <Route
                /*
                Since we already have some values in the database pointing to the old path we need to handle following routes:
                  - /id/{id}
                  - /id/{id}/details
                  - /{id} - new damage appraiser path
                 */
                path={`${PATH_DAMAGE}/id?/:id?/details?`}
                element={<AuthRoute element={<DamageAppraisalView />} />}
            />
            <Route
                path={`/damage-new/:id?`}
                element={<AuthRoute element={<DamageRedirect />} />}
            />

            <Route
                path={`${PATH_MY_ORDERED_CAR_WITH_ID}/videos`}
                element={<AuthRoute element={<Videos />} />}
            />

            <Route
                path={PATH_MY_ORDERED_CAR_WITH_ID}
                element={<AuthRoute element={<MyOrderedCar />} />}
            />

            <Route
                path={PATH_PROFILE_OVERVIEW}
                element={<AuthRoute element={<ProfileView />} />}
            />
            <Route
                path={PATH_DELETE_ACCOUNT}
                element={<AuthRoute element={<DeleteAccount />} />}
            />
            <Route
                path={`${PATH_MY_CAR}/payload-calculator`}
                element={<AuthRoute element={<PayloadCalculator />} />}
            />

            <Route path="*" element={<NoContent />} />
        </Routes>
    );
}
