import { IconVariantProvider, LoadingSpinner } from '@moller/design-system';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import axios from 'axios';
import { FetchersProvider } from 'external-apis';
import { FeatureToggleProvider } from 'feature-toggle';
import { ReactElement, Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from 'src/features/footer';
import Header from 'src/features/header';
import LocalStorage from 'src/utils/browser/LocalStorage';
import { sessionAppenderMiddleware } from 'src/utils/http/middlewares/sessionAppenderMiddleware';
import { createGlobalStyle, styled } from 'styled-components';
import { ThemeContextProvider } from 'theming';
import ScrollToTopOnNavigate from './ScrollToTopOnNavigate';
import { QUERY_PARAM_STORAGE } from './constants';
import AppMenu from './features/app-menu';
import { localOverrideUrls } from './features/booking';
import useInitializer from './initialization/useInitializer';
import { PATH_BEGIN, PATH_HOME } from './routing/routes';
import SplashScreen from './shared/SplashScreen';
import AppBundleUpdater from './utils/AppBundleUpdater';
import { CarTransitionContextProvider } from './utils/CarTransistionContext';
import StatusBarHandler from './utils/StatusBarHandler';
import { getPartyId } from './utils/auth/authenticationUtils';
import { useLoginRedirect } from './utils/auth/loginRedirect';
import DevToolsActivator from './utils/dev-tools/DevToolsActivator';
import { themeOverride } from './utils/dev-tools/devToolsUtils';
import getCurrentEnvironment from './utils/environment/getCurrentEnvironment';
import { hideSplashScreen } from './utils/hideSplashScreen';
import { authMiddleware } from './utils/http/middlewares/authMiddleware';
import { isMobileApp } from './utils/mobile';
import useCurrentPage from './utils/useCurrentPage';

const GlobalTextStyles = createGlobalStyle`
    html, body {
        background-color: var(--moller-color-background);
        color: var(--moller-color-on-background);
        min-height: 100%;

        reach-portal {
            z-index:100;
        }
    }
`;

const AppMenuSpacer = styled.div`
    height: 70px;
`;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 3600000, // one hour
            staleTime: 300000, // five minutes
            retry: (failureCount: number, error: unknown) => {
                // Retry 3 times before giving up
                if (failureCount > 4) {
                    return false;
                }
                if (axios.isAxiosError(error) && error?.response) {
                    // Don't retry on 400 errors
                    if (
                        error.response.status >= 400 &&
                        error.response.status < 500
                    ) {
                        return false;
                    }

                    return true;
                }
                return true;
            },
        },
    },
});

const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
});

const environment = getCurrentEnvironment().name;

if (environment === 'prod') {
    console.log('Setting up local storage cache for prod');
    void persistQueryClient({
        queryClient,
        persister: localStoragePersister,
    });
}
const fetcherConfigs = {
    port: {
        localEnvironmentOverride: localOverrideUrls.PORT_BASE_URI,
        middlewares: [authMiddleware(), sessionAppenderMiddleware()],
    },
    carcare: {
        localEnvironmentOverride: localOverrideUrls.CAR_CARE_BASE_URI,
        middlewares: [authMiddleware()],
    },
    bilhold: {
        localEnvironmentOverride: localOverrideUrls.BILHOLD_BASE_URI,
        middlewares: [authMiddleware(), sessionAppenderMiddleware()],
    },
};

export const Layout = (): ReactElement => {
    useLoginRedirect();
    const currentPage = useCurrentPage();
    useInitializer();
    const partyId = getPartyId();

    const syncLogout = (event: StorageEvent) => {
        if (event.key === 'logout') {
            window.location.assign(PATH_BEGIN);
        }
    };

    useEffect(() => {
        window.addEventListener('storage', syncLogout);

        // this will clean up the event every time the component is re-rendered
        return () => {
            window.removeEventListener('storage', syncLogout);
        };
    }, []);

    useEffect(() => {
        const queryParameters = window.location.search;
        LocalStorage.set(QUERY_PARAM_STORAGE, queryParameters);
        return () => {
            LocalStorage.remove(QUERY_PARAM_STORAGE);
        };
    }, []);

    useEffect(() => {
        const isOnBegin = location.pathname.includes(PATH_BEGIN);
        const timeout = isOnBegin ? 1500 : 5000;
        setTimeout(() => {
            hideSplashScreen();
        }, timeout);
    });

    const shouldShowHeader = () => {
        if (isMobileApp()) {
            if (location.pathname.includes(PATH_BEGIN)) {
                return true;
            }
            if (location.pathname.includes(PATH_HOME)) {
                return true;
            }
            return false;
        }
        return true;
    };

    const environment = getCurrentEnvironment().name;

    const featureToggleContext = {
        userId: partyId || '',
    };

    return (
        <>
            <ScrollToTopOnNavigate />
            <FeatureToggleProvider
                app="bilhold"
                env={environment}
                context={featureToggleContext}
            >
                <DevToolsActivator />
                <ThemeContextProvider
                    defaultTheme={'bilhold'}
                    overrideTheme={themeOverride}
                >
                    <GlobalTextStyles />
                    <CarTransitionContextProvider>
                        <FetchersProvider
                            environment={
                                environment === 'staging'
                                    ? 'stage'
                                    : environment
                            }
                            configs={fetcherConfigs}
                        >
                            <QueryClientProvider client={queryClient}>
                                <AppBundleUpdater />
                                <StatusBarHandler />
                                <Suspense
                                    fallback={<LoadingSpinner isFullScreen />}
                                >
                                    <SplashScreen />
                                    <IconVariantProvider variant="outlined">
                                        {shouldShowHeader() ? (
                                            <Header currentPage={currentPage} />
                                        ) : null}
                                        <Outlet />
                                        {isMobileApp() ? (
                                            <>
                                                <AppMenu
                                                    currentPage={currentPage}
                                                />
                                                <AppMenuSpacer />
                                            </>
                                        ) : (
                                            <Footer />
                                        )}
                                    </IconVariantProvider>
                                </Suspense>
                            </QueryClientProvider>
                        </FetchersProvider>
                    </CarTransitionContextProvider>
                </ThemeContextProvider>
            </FeatureToggleProvider>
        </>
    );
};
