import {
    AlertBanner,
    Column,
    Headline,
    IconButton,
    LoadingSpinner,
    Row,
} from '@moller/design-system';
import { Divider } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import { openPdfWithAuth } from 'src/utils/auth/authUtils';
import scrollToTopOfPage from 'src/utils/browser/scrollToTopOfPage';
import {
    temporaryMessageId,
    useMessageThread,
    useMessagesInThread,
} from 'src/utils/hooks/useMessageThreads';
import { useProfile } from 'src/utils/hooks/useProfile';
import { toServerUrl } from 'src/utils/http/utils/toServerUrl';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { openFileForMobileApp } from 'src/utils/native/pdf';
import { sortByTime } from 'src/utils/time/timeUtils';
import { styled } from 'styled-components';
import { CHAT_LIST } from '..';
import Message from './Message';
import { SendMessageInExistingThread } from './SendMessageInExistingThread';

function convertToActualMimeType(mimeType: 'PDF' | 'PNG') {
    switch (mimeType) {
        case 'PDF':
            return 'application/pdf';
        case 'PNG':
            return 'image/png';
    }
}

function getFileExtension(mimeType: 'PDF' | 'PNG') {
    switch (mimeType) {
        case 'PDF':
            return '.pdf';
        case 'PNG':
            return '.png';
    }
}

const MessageThreadContainer = styled(Column)`
    width: 100%;
    gap: var(--moller-spacing-l);
    margin-top: var(--moller-spacing-l);
`;

const StyledContainer = styled(Row)`
    margin-bottom: var(--moller-spacing-l);
`;

export const IconContainer = styled.div`
    display: flex;
    gap: var(--moller-spacing-base);
    align-items: center;
    margin-bottom: 0.5rem;
`;

export const MessageThreadsChat = () => {
    const { threadId } = useParams<{ threadId: string }>();
    const { data: profile } = useProfile();
    const threadIdFromParam = threadId ?? '';

    const navigate = useNavigate();

    const {
        data: thread,
        isLoading: isLoadingThread,
        isError: isErrorThread,
    } = useMessageThread(threadId);

    const { data: messages, isLoading: isLoadingMessages } =
        useMessagesInThread(threadId);

    const navigateToChat = () => {
        navigate(`/messages/?category=${CHAT_LIST}`);
    };

    const sortedMessages =
        messages &&
        [...messages].sort((a, b) => sortByTime(a.createdAt, b.createdAt));

    return (
        <CenteredPageContent>
            <StyledContainer>
                <IconButton
                    icon="arrow_backward"
                    label={lang.back}
                    showLabel="right"
                    onClick={navigateToChat}
                />
            </StyledContainer>
            {isLoadingMessages ||
                (isLoadingThread && <LoadingSpinner isFullScreen />)}
            {isErrorThread ? (
                <AlertBanner
                    type="error"
                    message={lang.general_error_try_again}
                />
            ) : (
                <Column gap={'m'}>
                    <Column gap={'m'}>
                        <Headline>{thread?.title}</Headline>
                        <p>
                            Lurer du på noe angående din verkstedtime? Vi svarer
                            deg innen 1 virkedag (åpningstid 09-15). Ring oss om
                            det er noe som haster.
                        </p>
                        <Divider />
                        {isLoadingMessages && <LoadingSpinner isFullScreen />}
                        <MessageThreadContainer>
                            {sortedMessages?.map((m) => (
                                <Message
                                    attachments={m.attachments?.map((a) => {
                                        const fileExtension = getFileExtension(
                                            a.mimeType
                                        );
                                        const fileName = `Bilhold-Attachment-${a.attachmentId}${fileExtension}`;
                                        return {
                                            filePath: toServerUrl(a.filePath),
                                            mimeType: convertToActualMimeType(
                                                a.mimeType
                                            ),
                                            fileName,
                                        };
                                    })}
                                    downloadAttachment={(attachment) => {
                                        if (isMobileApp()) {
                                            void openFileForMobileApp(
                                                attachment.filePath,
                                                attachment.mimeType,
                                                attachment.fileName
                                            );
                                        } else {
                                            void openPdfWithAuth(
                                                attachment.filePath,
                                                attachment.fileName
                                            );
                                        }
                                    }}
                                    messageText={m.content}
                                    sentTime={m.createdAt ?? ''}
                                    isSentByMe={!m.sender.internal}
                                    key={m.messageId}
                                    senderName={
                                        m.sender.internal
                                            ? m.sender.name?.split(' ')[0]
                                            : ''
                                    }
                                    isOptimistic={
                                        m.messageId === temporaryMessageId
                                    }
                                />
                            ))}
                        </MessageThreadContainer>
                        <Divider />
                        <SendMessageInExistingThread
                            threadId={threadIdFromParam}
                            senderName={`${profile?.firstname} ${profile?.surname}`}
                        />
                    </Column>
                    <Divider />
                    <Row horizontalAlign="end" verticalAlign="center">
                        <IconButton
                            icon="arrow_upward"
                            label={lang.toTop}
                            showLabel="left"
                            onClick={() => scrollToTopOfPage()}
                        />
                    </Row>
                </Column>
            )}
        </CenteredPageContent>
    );
};
